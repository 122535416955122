<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <!-- brand logo -->
            <router-link
                to="/"
                class="brand-logo d-flex align-center"
            >
                <v-img
                    :src="appLogo"
                    max-height="30px"
                    max-width="30px"
                    alt="logo"
                    contain
                    class="me-3 "
                ></v-img>

                <h2 class="text--primary">
                    {{ appName }}
                </h2>
            </router-link>
            <!--/ brand logo -->

            <v-row class="auth-row ma-0">
                <v-col
                    lg="8"
                    class="d-none d-lg-block position-relative overflow-hidden pa-0"
                >
                    <div class="auth-illustrator-wrapper">
                        <!-- triangle bg -->
                        <img
                            height="362"
                            class="auth-mask-bg"
                            :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
                        />

                        <!-- tree -->
                        <v-img
                            height="226"
                            width="300"
                            class="auth-tree"
                            src="@/assets/images/misc/tree-4.png"
                        ></v-img>

                        <!-- 3d character -->
                        <div class="d-flex align-center h-full pa-16 pe-0">
                            <v-img
                                contain
                                max-width="100%"
                                height="692"
                                class="auth-3d-group"
                                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
                            ></v-img>
                        </div>
                    </div>
                </v-col>

                <v-col
                    lg="4"
                    class="d-flex align-center auth-bg pa-10 pb-0"
                >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="8"
                            md="6"
                            lg="12"
                            class="mx-auto"
                        >
                            <v-card
                                flat
                                :disabled="loading"
                            >
                                <v-card-text>
                                    <p
                                        class="text-2xl font-weight-semibold text--primary mb-2"
                                    >
                                        {{ $trans('app_name') }} 👋🏻
                                    </p>
                                    <p class="mb-2">
                                        {{ $trans('hint.login.subtitle') }}
                                    </p>
                                    <p v-if="error" class="mb-2 error--text">{{ error }}</p>
                                </v-card-text>

                                <!-- login form -->
                                <v-card-text>
                                    <v-form
                                        v-model="isValid"
                                        ref="loginForm"
                                        @submit.prevent
                                    >
                                        <v-text-field
                                            v-model="email"
                                            outlined
                                            :rules="rules.email"
                                            :label="$trans('Login')"
                                            placeholder="john@example.com"
                                            hide-details="auto"
                                            class="mb-6"
                                        ></v-text-field>

                                        <v-text-field
                                            v-model="password"
                                            outlined
                                            :rules="rules.password"
                                            :type="isPasswordVisible ? 'text' : 'password'"
                                            :label="$trans('Password')"
                                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                                            hide-details="auto"
                                            class="mb-2"
                                            @click:append="isPasswordVisible = !isPasswordVisible"
                                        ></v-text-field>

                                        <div
                                            class="d-flex align-center flex-wrap"
                                        >
                                            <v-spacer/>
                                            <router-link
                                                class="ms-3"
                                                :to="{name: 'auth.forgot_password'}"
                                            >
                                                {{ $trans('Forgot password?') }}
                                            </router-link>
                                        </div>

                                        <v-btn
                                            :disabled="!isValid"
                                            :loading="loading"
                                            block
                                            color="primary"
                                            type="submit"
                                            class="mt-6"
                                            @click="login"
                                        >
                                            {{ $trans('Sign in') }}
                                        </v-btn>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import dashboardThemeConfig from '@themeConfig'
import appsBootstrap from '@/plugins/appsBootstrap'
import EventEmitter from '@/plugins/EventEmitter'
import { emailValidator, required } from '@/utils/validation'
import { AuthProvider } from '@apps/auth/utils'

export default {
    name: 'Login',
    computed: {
        rules() {
            return {
                email: [
                    required,
                    emailValidator
                ],
                password: [
                    required
                ],
            }
        },
    },
    data: () => ({
        loading: false,
        email: '',
        password: '',
        isPasswordVisible: false,
        icons: {
            mdiEyeOutline: 'mdi-eye-outline',
            mdiEyeOffOutline: 'mdi-eye-off-outline'
        },
        appName: dashboardThemeConfig.app.name,
        appLogo: dashboardThemeConfig.app.logo,
        isLoginFiled: false,
        error: null,
        isValid: true
    }),
    beforeCreate() {
        this.$store.commit('auth/RESET_STATE')
        EventEmitter.emit('guest:bootstrap')
    },
    methods: {
        async login() {
            if(!this.$refs.loginForm.validate()) {
                return
            }

            this.loading = true
            this.isLoginFiled = false

            try {
                this.$notification.disabled()
                await this.$store.dispatch('auth/login', {
                    email: this.email,
                    password: this.password
                })

                const auth = new AuthProvider()

                await auth.fetchAuthUser()

                await appsBootstrap.boot(this)
                await this.$router.push({ name: 'home' })
            } catch (error) {
                if(error && error.response) {
                    this.error = error.response.data.message
                }
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
 @import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
